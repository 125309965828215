<template>
  <!-- 会员等级管理 -->
  <div class="container">
    <el-card class="head">
      <div>
        <div class="OperButton">
          <router-link to="/setting/member/level/add">
            <div class="addBtn">
              <img src="../../../assets/images/add.png" style="margin-right: 5px;width: 13px;">添加
            </div>
          </router-link>
        </div>
      </div>
    </el-card>
    <div style="height: 10px"></div>
    <el-card class="body">
      <!-- 列表 -->
      <div class="dataTable">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;font-family: PingFangRoutine;"
          :header-cell-style="tableHeaderColor"
          :cell-style="{ color: '#2C2C2C', fontSize: '14px' }"
        >
          <el-table-column prop="id" label="ID" align="center">
          </el-table-column>
          <el-table-column prop="name" label="会员等级名称" align="center">
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template #default="scope">
              <div class="operating">
                <div class="edit" @click="levelEdit(scope.row)">
                  <img style="width: 18px;height: 18px;" src="../../../assets/images/edit.png"/>
                </div>
                <div class="delete" @click="levelDel(scope.row.id)">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 列表 -->
    </el-card>
    <div style="height: 10px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
      <!-- 分页 -->
    </div>
  </div>
</template>
<script>
import { levelList, levelDel } from "@/api/member";
import "../../../font-style/font.css";
export default {
  data() {
    return {
      currPage: "",
      datatime: [],
      tableData: [],
      source: [],
      level: [],
      total: null,
      setting: [],
    };
  },
  activated() {
    this.list();
  },
  methods: {
    //会员列表
    list() {
      levelList({ currPage: this.currPage }).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total * 1;
        this.currPage=''
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.currPage = val;
      this.list();
    },
    // table
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px'";
      }
    },
    //删除
    async levelDel(id) {
      if (!(await this._box())) return;
      levelDel({ id: id }).then((res) => {
        let totalPage = Math.ceil((this.total - 1) / 10);
        let currentPage = this.currPage > totalPage ? totalPage : this.currPage;
        this.currPage = currentPage < 1 ? 1 : currentPage;
        this.list();
      });
    },
    //编辑
    levelEdit(val) {
      this.$router.push({
        path: "/setting/member/level/edit",
        query: {
          id: val.id,
          name: val.name,
          remark: val.remark,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/css/common.less";
/deep/ .head .el-card__body{
    padding-left: 0px;
  }
  .dataOper{
    padding: 20px;
    padding-left: 0px;
  }
</style>
